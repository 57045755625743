import { useTranslation } from 'react-i18next'
import { IButtonProps, ICommandBarItemProps, IRenderFunction } from '@fluentui/react'
import UserActionsIcon from '../components/UserActionsIcon'
import { Person } from '@/shared/types/person'
import { useCallback } from 'react'
import { NavItems } from '../constants/navItems'
import { useHistory } from 'react-router-dom'
import NotificationIcon from '../components/NotificationIcon'
import { useSelector } from 'react-redux'
import { selectIsCAEmployee, selectIsImpersonating } from '@/features/auth/redux/authSelectors'

type CommandBarItemsPropsMobile =
  Pick<ICommandBarItemProps, 'key' | 'href' | 'className' | 'iconProps' | 'text'> &
  {onRenderIcon: IRenderFunction<IButtonProps>, onClick?: IButtonProps['onClick']};

interface CommandBarItemsProps {
  desktopProps: Array<ICommandBarItemProps>;
  mobileProps: Array<CommandBarItemsPropsMobile>;
}

export default function useCommandBarProps(items: NavItems, person: Person): CommandBarItemsProps {
  const { t } = useTranslation('common')
  const history = useHistory()

  const renderNotificationIcon = useCallback(() => <NotificationIcon />, [])
  const renderUserActionsIcon = useCallback(() => <UserActionsIcon person={person} />, [person])

  const isCAEmployee = useSelector(selectIsCAEmployee)
  const isImpersonating = useSelector(selectIsImpersonating)

  const REMOVE_TOOLTIP_FOR = t(`nav:myaccount`)

  function getLocaleText<T>(item): T {
    const commandBarItemWithLocaleText = { ...item, text: t(`nav:${item.key}`) }
    if (item?.subMenuProps?.items?.length) {
      commandBarItemWithLocaleText.subMenuProps = {
        ...commandBarItemWithLocaleText.subMenuProps,
        items: commandBarItemWithLocaleText.subMenuProps.items.map(getLocaleText),
        className: 'c-nav-submenu',
      }
    }
    return commandBarItemWithLocaleText
  }

  function overrideSubmenuHref(_item: ICommandBarItemProps): ICommandBarItemProps {
    const item = { ..._item }
    if (item?.subMenuProps?.items?.length) {
      item.subMenuProps.items = item.subMenuProps.items.map((cbi: ICommandBarItemProps) => { 
        if ('href' in cbi && !('onClick' in cbi)) {
          return {
            ...cbi,
            onClick: (e) => { e.preventDefault(); history.push(cbi.href) }
          }
        } 
        return cbi
      })
    }
    return item
  }

  const commonProps: Array<NavItems[keyof NavItems]> = [
    items.support,
    { ...items.useractions, onRenderIcon: renderUserActionsIcon }
  ]

  const desktopProps: CommandBarItemsProps['desktopProps'] = [
    !isCAEmployee && !isImpersonating && { ...items.messages, onRenderIcon: renderNotificationIcon },
    ...commonProps
  ]
    .filter(Boolean)
    .map(getLocaleText)
    .map(overrideSubmenuHref)
    .map(item => ({ ...item, iconOnly: true, text: (item.text?.toLowerCase() === REMOVE_TOOLTIP_FOR.toLocaleLowerCase()) ? '' : item.text }))

  const mobileProps: CommandBarItemsProps['mobileProps'] = [
    !isCAEmployee && !isImpersonating && { ...items.messages, href: '/', onRenderIcon: renderNotificationIcon },
    items.support,
    { ...items.myaccount, onRenderIcon: renderUserActionsIcon }
  ]
    .filter(Boolean)
    .map(getLocaleText)
    .map((item: CommandBarItemsPropsMobile) => ({
      ...item, className: 'c-nav__command-bar-button --mobile',
      onClick: (e) => { e.preventDefault(); history.push(item.href) }
    }))

  return { desktopProps, mobileProps }
}