import axios from 'axios';
import { mainService } from '.'
import { IBoxFolderTreeItem } from '@/shared/types/swagger'
import { CollaboratorAccess } from '@/features/client/components/ClientDocuments/types'
import { IBoxCollaboration } from '@/shared/types/swagger'
import { useSelector } from 'react-redux'
import { selectClaims } from '@/features/auth/redux/authSelectors'

type BoxTokenParams = {
  accessToken: string
  userId?: string
}

export const DISABLE_UPLOAD_NOTIFICATION_METADATA = 'disableUploadNotification';

export type CollaborationEditData = { collaborationId: string, permissionLevel: CollaboratorAccess }

export type CollaborationAddData = {
  permissionLevel: CollaboratorAccess;
  boxId: string;
  folderId: string;
}

export type FileMetadataFetchRequest = {
  fileId: string;
}

export type FileMetadataCreateRequest = {
  fileId: string;
  key: string;
  value: string;
  template?: string;
}

export type FileMetadataEditRequest = {
  action: 'add' | 'update';
  fileId: string;
  key: string;
  value: string;
  template?: string;
}

export interface IBoxFileMetadataEditResponse {
  $canEdit: boolean;
  $id: string;
  $parent: string;
  $scope: string;
  $template: string;
  $type: string;
  $typeVersion: number;
  $version: number;
  [propertyKey: string]: any;
}

export interface IBoxFileMetadataFetchResponse {
  entries: IBoxFileMetadataEditResponse[];
  limit: number;
}

export interface IBoxFolderDetails {
  accountId: string,
  boxFolderId: string,
  folderType: string,
  folderName: string,
  boxId: string,
  parentFolderId: string,
}

export interface IBoxFolderNotificationSetting {
  folderId: string,
  isEnabled: boolean,
}

export interface IBoxFolderCollectionEntry {
  type: 'folder' | 'file',
  id: string,
  name: string,
  size: number,
}

export interface IBoxFolderCollection {
  id: string,
  permissions: {
    can_delete: boolean,
    can_download: boolean,
    can_invite_collaborator: boolean,
    can_rename: boolean,
    can_set_share_access: boolean,
    can_share: boolean,
    can_upload: boolean,
  },
  item_collection: {
    entries: IBoxFolderCollectionEntry[],
    limit: number,
    offet: number,
    order: {
      by: string,
      direction: 'ASC' | 'DESC',
    }[],
    total_count: number,
  }
}

export interface IBoxFolderContentExplorerState {
  boxResponse: IBoxFolderCollection
  boxFolder?: IBoxFolderCollection
  selectedFiles?: {
    [fileId: string]: boolean
  }
}

export interface IBoxFileDownload {
  fileId: string,
  fileName: string,
  success: boolean,
}

export interface IBoxFolderRefreshPermissionInput {
  accountId: string,
  permissionName: string,
}

const mainBoxServices = mainService
  .enhanceEndpoints({ addTagTypes: ['Collaboration', 'BoxFolderNotificationSetting'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        getBoxToken: build.query<BoxTokenParams, string | null>({
          query: (accountId) => ({ url: `/Box/UserToken${accountId ? `?accountId=${accountId}` : ''}`, method: 'GET' }),
          keepUnusedDataFor: 18000,
        }),
        getCollaborators: build.query<Record<string, IBoxCollaboration>, string>({
          query: (folderId) => ({ url: `/Box/FolderPerms?folderId=${folderId}`, method: 'GET' }),
          transformResponse: (collaborations: Array<IBoxCollaboration>) => collaborations
            .filter((collaboration) => collaboration.accessibleBy.type === 'user')
            .reduce((acc, cur) => {
              acc[cur.accessibleBy.id] = cur
              return acc
            }, {}),
          providesTags: ['Collaboration']
        }),
        getBoxClientRootFolder: build.query<string, string>({
          query: (accountId) => ({ url: `/Box/ClientRootFolder?accountId=${accountId}`, method: 'GET' }),
          transformResponse: (response: { id: string }) => response.id
        }),
        getBoxRecentItems: build.query<Array<IBoxFolderTreeItem>, void>({
          query: () => ({ url: '/Box/UserRecentFiles', method: 'GET' })
        }),
        addCollaboration: build.mutation<IBoxCollaboration, CollaborationAddData>({
          query: (data) => ({
            url: `/Box/AddUserFolderCollaboration?boxId=${data.boxId}&folderId=${data.folderId}&permissionLevel=${data.permissionLevel}`,
            method: 'POST'
          }),
          invalidatesTags: ['Collaboration']
        }),
        editCollaboration: build.mutation<IBoxCollaboration, CollaborationEditData>({
          query: (data) => (
            {
              url: `/Box/EditCollaboration?collaborationId=${data.collaborationId}&permissionLevel=${data.permissionLevel}`,
              method: 'PUT'
            }
          ),
          invalidatesTags: ['Collaboration']
        }),
        deleteCollaboration: build.mutation<IBoxCollaboration, Partial<CollaborationEditData>>({
          query: (data) => ({ url: `/Box/DeleteCollaboration?collaborationId=${data.collaborationId}`, method: 'DELETE' }),
          invalidatesTags: ['Collaboration']
        }),
        getBoxFolderDetails: build.query<IBoxFolderDetails, string>({
          query: (boxId) => ({ url: `/Box/BoxFolderDetails?boxId=${boxId}`, method: 'GET' }),
        }),
        getBoxFolderUploadNotification: build.query<boolean, string>({
          query: (folderId) => ({ url: `/Box/NotificationPreferences?folderId=${folderId}`, method: 'GET' }),
          transformResponse: (response: IBoxFolderNotificationSetting) => {
            return response.isEnabled
          },
          providesTags: (result, error, id) => {
            return [{
              type: 'BoxFolderNotificationSetting',
              id,
            }]
          },
        }),
        getBoxFolderNotificationSetting: build.mutation<boolean, string>({
          query: (folderId) => ({
            url: `/Box/NotificationPreferences?folderId=${folderId}`,
            method: 'GET',
          }),
          transformResponse: (response: IBoxFolderNotificationSetting) => {
            return response.isEnabled
          },
          invalidatesTags: ['BoxFolderNotificationSetting'],
        }),
        saveBoxFolderNotificationSetting: build.mutation<void, IBoxFolderNotificationSetting>({
          query: (data) => ({
            url: `/Box/UpdateNotificationPreferences?folderId=${data.folderId}&notificationPreference=${data.isEnabled}`,
            method: 'POST',
            data,
          }),
          invalidatesTags: ['BoxFolderNotificationSetting'],
        }),
        refreshBoxFolderPermission: build.mutation<void, IBoxFolderRefreshPermissionInput>({
          query: (data) => ({
            url: `/Box/RefreshBoxFolderPermission?accountId=${data.accountId}&permissionName=${data.permissionName}`,
            method: 'POST',
            data,
          }),
        }),
        redefineBoxFolderStructure: build.mutation<void, string>({
          query: (accountId) => ({
            url: `/Box/RedefineBoxFolderStructure?accountId=${accountId}`,
            method: 'POST',
          }),
        }),
      }
    }
  })

export const {
  useGetBoxTokenQuery,
  useGetBoxClientRootFolderQuery,
  useGetCollaboratorsQuery,
  useEditCollaborationMutation,
  useDeleteCollaborationMutation,
  useAddCollaborationMutation,
  useGetBoxRecentItemsQuery,
  useGetBoxFolderDetailsQuery,
  useGetBoxFolderUploadNotificationQuery,
  useGetBoxFolderNotificationSettingMutation,
  useSaveBoxFolderNotificationSettingMutation,
  useRefreshBoxFolderPermissionMutation,
  useRedefineBoxFolderStructureMutation,
  reducerPath
} = mainBoxServices

export function useGetManagePermissionsForBoxFolder(boxId: string) {
  const userClaims = useSelector(selectClaims)
  const { data, isFetching, isError } = useGetBoxFolderDetailsQuery(boxId, { skip: !boxId })

  // skips manage permissions for Client Contact users
  if (!userClaims?.CAEmployee) {
    return false
  }

  // skips manage permissions if API call in progress
  if (isFetching) {
    return false
  }

  // displays manage permissions if API call errors
  if (isError) {
    return true
  }

  // displays manage permissions no API data
  if (!data) {
    return true
  }

  //  displays manage permissions if folder type is not root folder or current user is a Sys Admin user
  const { folderType } = data
  const securityGroups = (userClaims?.SecurityGroups as any[] || []) as string[]
  return (folderType !== 'Root') || (securityGroups.some(s => s === 'SSO_ClientWebsite SysAdmin'))
}

export const downloadBoxFile = async (folderId: string, folderName: string, fileId: string, fileName: string, accessToken: string) => {
  let success = false

  try {
    // Attempt to fetch the file content
    const response = await axios.get(`https://dl.boxcloud.com/api/2.0/files/${fileId}/content`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob', // Important for handling the file stream
      maxRedirects: 0, // Prevent axios from automatically following redirects
      validateStatus: function(status) {
        return status >= 200 && status < 303; // Accept 2xx status codes and 302
      },
    });

    let downloadedFileName = `${response.headers['Content-Disposition'] || ''}`?.match(/filename\=(.*)\;/)?.[1]?.replace(/[\'|\"]/g, "")
      || fileName
    let blobData = response.data;

    // If the response is a 302 redirect
    if (response.status === 302) {
      const locationUrl = response.headers['location'];
      // Perform a second GET request to the location URL
      const secondResponse = await axios.get(locationUrl, {
        responseType: 'blob',
      });
      blobData = secondResponse.data;
      downloadedFileName = `${secondResponse.headers['Content-Disposition'] || ''}`?.match(/filename\=(.*)\;/)?.[1]?.replace(/[\'|\"]/g, "")
        || fileName
    }

    // Create a temporary link to download the blob
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', downloadedFileName || `file${fileId}`); // Set the file name
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);

    success = true
  } catch (error) {
    console.error('Error downloading file:', error);
    success = false
  }

  return {
    folderId,
    folderName,
    fileId,
    fileName,
    success,
  } as IBoxFileDownload
};