import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { exportGridData, useGetPortfolioSettingsByIdQuery, useGetRecentTransactionQuery } from '@/shared/api/services/portfolioService'
import { Card, GeneralError, Section } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { Link } from 'react-router-dom'
import SkeletonClientPortfolio from '../Skeletons/SkeletonClientPortfolio'
import RecentTradeGrid from './RecentTradeGrid/grid/RecentTradeGrid'
import RecentTradesMobile from './RecentTradesMobile/RecentTradeMobile'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi } from 'ag-grid-community'
import { useState } from 'react'

import './RecentTrade.scss'

export interface RecentTradeProps {
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string
}

export default function RecentTrade({ 
  portfolioId, 
  asOfDate
}: RecentTradeProps) {
  const isMobile = useIsMobile('xs')
  const isVerticalMobile = useIsMobile('sm')
  const listLimit = 5
  const { data, isFetching, isError, refetch } = useGetRecentTransactionQuery({ portfolioId, asOfDate, listLimit })
  const { data: portfolioSettings } = useGetPortfolioSettingsByIdQuery(portfolioId)
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  
  if (isError) {
    return (
      <Card className='c-onboarding-welcome__left__card card-left-min' ariaLabel='Welcome'>
        <Section className='c-portfolio-section' title='Recent Trades'>
          <GeneralError title='Failed to load Portfolio Recent Trades' onClick={refetch} />
        </Section>
      </Card>
    )
  }
  
  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Recent Trades',
      fileName: `recent-trades-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
    })
  }

  const linkAllTrades = window.location.pathname + '?tab=trades'
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, portfolioSettings?.headerDateFormat || 'MMM dd, yyyy')}`
  const linkAllTradesElem =  isMobile ? [] : ([<Link className='all-trades' to={linkAllTrades} key={0}>View all Trades</Link>,
    <div  key='downloadRecentTradesBtn' className='download-button-container'>
      <DefaultButton className='download-button'  onClick={handleExcelDownloadClick}>
        <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
      </DefaultButton>
    </div>,])

  return (
    <Card className='c-onboarding-welcome__left__card card-left-min card-recent-trade-left  card-ag-grid-recent-trades' ariaLabel='Welcome'>
      {
        isFetching ? <SkeletonClientPortfolio /> :
        <Section className='c-portfolio-section' title='Recent Trades' actions={linkAllTradesElem}>
          {isMobile || isVerticalMobile ? <RecentTradesMobile fullDateFormat={portfolioSettings?.fullDateFormat} rows={data} /> : <RecentTradeGrid portfolioSettings={portfolioSettings} rows={data} sendGridApi={setGridApi} />}
        </Section>
      }
    </Card>
  )
}
