import { moreLinks } from '@/features/more/constants/linkContent'
import { NavMenuItem } from '@/shared/components/NavMenu/types'
import { EUserCan } from '@/features/auth/types'
import { ELinks } from '../types/Links'
import { ILEVEL_CA_EMPLOYEE_URL, ILEVEL_CLIENT_CONTACT_URL, PW_CA_EMPLOYEE_URL, PW_CLIENT_CONTACT_LINK_URL } from '@/environment/environment.constants'
import { useSelector } from 'react-redux'
import { selectIsClientContactDocumentViewOnly } from '@/features/auth/redux/authSelectors'
import { useGetDashboardSettings } from '../api/services/legacyService'
import { DashboardSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'

export enum NavMenuItems {
  Dashboard = 1,
  Optica,
  OpticaResearchPrivate,
  OpticaResearchPublic,
  OpticaBenchmarks,
  OpticaPeers,
  Documents,
  Events,
  More,
  Insights,
  ClientWelcome,
  ClientDocuments,
  ClientTimeline,
  ClientSupport,
  OnboardingWelcome,
  OnboardingDocuments,
  OnboardingTimeline,
  OnboardingSupport,
  ILevel,
  PortfolioWorkbench,
  OnboardingDashboard,
}

export const navMenuItems: Record<number, NavMenuItem> = {
  [NavMenuItems.Dashboard]: {
    id: NavMenuItems.Dashboard,
    titleKey: 'dashboard',
    url: '/',
    authority: EUserCan.Access_Dashboard,
  },
  [NavMenuItems.Optica]: {
    id: NavMenuItems.Optica,
    titleKey: 'optica',
    itemIds: [NavMenuItems.OpticaResearchPrivate, NavMenuItems.OpticaResearchPublic, NavMenuItems.OpticaBenchmarks, NavMenuItems.OpticaPeers],
    authority: EUserCan.View_Optica_Links
  },
  [NavMenuItems.OpticaResearchPrivate]: {
    id: NavMenuItems.OpticaResearchPrivate,
    titleKey: 'researchPrivate',
    url: moreLinks[ELinks[ELinks.OpticaResearchPrivate]],
    target: '_blank',
    authority: EUserCan.View_Optica_Links
  },
  [NavMenuItems.OpticaResearchPublic]: {
    id: NavMenuItems.OpticaResearchPublic,
    titleKey: 'researchPublic',
    url: moreLinks[ELinks[ELinks.OpticaResearchPublic]],
    target: '_blank',
    authority: EUserCan.View_Optica_Links
  },
  [NavMenuItems.OpticaBenchmarks]: {
    id: NavMenuItems.OpticaBenchmarks,
    titleKey: 'benchmarks',
    url: moreLinks[ELinks[ELinks.OpticaBenchmark]],
    target: '_blank',
    authority: EUserCan.View_Optica_Links
  },
  [NavMenuItems.OpticaPeers]: {
    id: NavMenuItems.OpticaPeers,
    titleKey: 'peers',
    url: moreLinks[ELinks[ELinks.OpticaPeers]],
    target: '_blank',
    authority: EUserCan.View_Optica_Links
  },
  [NavMenuItems.Documents]: {
    id: NavMenuItems.Documents,
    titleKey: 'documents',
    url: '/documents'
  },
  [NavMenuItems.Events]: {
    id: NavMenuItems.Events,
    titleKey: 'events',
    url: '/events',
    authority: EUserCan.Access_Events,
  },
  [NavMenuItems.More]: {
    id: NavMenuItems.More,
    titleKey: 'more',
    url: '/more',
    authority: EUserCan.Access_More_Links,
  },
  [NavMenuItems.Insights]: {
    id: NavMenuItems.Insights,
    titleKey: 'Insights',
    url: '/insights',
    authority: EUserCan.Access_Insights
  },
  [NavMenuItems.OnboardingWelcome]: {
    id: NavMenuItems.OnboardingWelcome,
    titleKey: 'welcome',
    url: '/onboarding/welcome'
  },
  [NavMenuItems.OnboardingDocuments]: {
    id: NavMenuItems.OnboardingDocuments,
    titleKey: 'documents',
    url: '/onboarding/documents'
  },
  [NavMenuItems.OnboardingTimeline]: {
    id: NavMenuItems.OnboardingTimeline,
    titleKey: 'timeline',
    url:  '/onboarding/timeline'
  },
  [NavMenuItems.OnboardingSupport]: {
    id: NavMenuItems.OnboardingSupport,
    titleKey: 'support2',
    url:  '/onboarding/support'
  },
  [NavMenuItems.ILevel]: {
    id: NavMenuItems.ILevel,
    titleKey: 'iLevel',
    target: '_blank',
    authority: EUserCan.View_iLevel_Links,
    getMenuURL: (isCAEmployee) => {
      if (isCAEmployee) {
        return ILEVEL_CA_EMPLOYEE_URL
      }
      return ILEVEL_CLIENT_CONTACT_URL
    },
  },
  [NavMenuItems.PortfolioWorkbench]: {
    id: NavMenuItems.PortfolioWorkbench,
    titleKey: 'Portfolio Workbench',
    target: '_blank',
    authority: EUserCan.View_Portfolio_Workbench_Link,
    getMenuURL: (isCAEmployee) => {
      if (isCAEmployee) {
        return PW_CA_EMPLOYEE_URL
      }
      return PW_CLIENT_CONTACT_LINK_URL
    },
  },
  [NavMenuItems.OnboardingDashboard]: {
    id: NavMenuItems.OnboardingSupport,
    titleKey: 'onboardingDashboard',
    url: '/dashboard/onboarding',
    authority: EUserCan.Access_Dashboard,
  },
}

export const clientNavMenuItems = (accountid: string | number): Record<number, NavMenuItem> => ({
  [NavMenuItems.ClientWelcome]: {
    id: NavMenuItems.ClientWelcome,
    titleKey: 'welcome',
    url: `/clients/${accountid}`,
    authority: EUserCan.Access_Client
  },
  [NavMenuItems.ClientTimeline]: {
    id: NavMenuItems.ClientTimeline,
    titleKey: 'timeline',
    url: `/clients/${accountid}/timeline`,
    authority: EUserCan.Access_Client
  },
  [NavMenuItems.ClientSupport]: {
    id: NavMenuItems.ClientSupport,
    titleKey: 'support2',
    url: `/clients/${accountid}/support`,
    authority: EUserCan.Access_Client
  },
})

export const mainNavMenuData = [
  NavMenuItems.Dashboard,
  NavMenuItems.Documents,
  NavMenuItems.Optica,
  NavMenuItems.ILevel,
  NavMenuItems.PortfolioWorkbench,
  NavMenuItems.Insights,
  NavMenuItems.Events,
  NavMenuItems.More,
]

export const mainNavMenuDataMultipleDashboard = [
  NavMenuItems.Dashboard,
  NavMenuItems.OnboardingDashboard,
  NavMenuItems.Documents,
  NavMenuItems.Optica,
  NavMenuItems.ILevel,
  NavMenuItems.PortfolioWorkbench,
  NavMenuItems.Insights,
  NavMenuItems.Events,
  NavMenuItems.More,
]

export const mainNavMenuDataProspectClients = [
  NavMenuItems.Dashboard,
  NavMenuItems.Documents,
]

export const mainNavMenuDataProspectClientsMultipleDashboard = [
  NavMenuItems.Dashboard,
  NavMenuItems.OnboardingDashboard,
  NavMenuItems.Documents,
]

export const mainNavMenuDataClientDocuments = [
  NavMenuItems.Documents,
]

export const getNavMenuItemURL = (navMenuItem: Partial<NavMenuItem>, isCAEmployee: boolean) => {
  if (navMenuItem) {
    const { getMenuURL, url } = navMenuItem
    if (getMenuURL) {
      const menuURL = getMenuURL(isCAEmployee)
      if (menuURL) {
        return menuURL
      }
    }
    return url || ''
  }

  return ''
}

export function useGetNavMenuData() {
  const isClientDocumentViewOnly = useSelector(selectIsClientContactDocumentViewOnly)
  const { isSuccess, data } = useGetDashboardSettings()

  let itemIds = mainNavMenuData

  if (isClientDocumentViewOnly) {
    itemIds = mainNavMenuDataClientDocuments
  } else {
    if (isSuccess) {
      const { enableMainInvestmentDashboard, enableClientOnboardingDashboard } = data || {} as DashboardSettings

      if (enableMainInvestmentDashboard && enableClientOnboardingDashboard) {
        // [CP-294] - Adds onboarding dashboard to standard menu when both dashboards enabled
        itemIds = mainNavMenuDataMultipleDashboard
      } else if (enableMainInvestmentDashboard) {
        itemIds = mainNavMenuData        
      } else if (enableClientOnboardingDashboard) {
        itemIds = mainNavMenuDataProspectClients
      }
    }
  }

  return itemIds;
}
