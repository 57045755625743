import { CSSProperties, useState, useRef, useLayoutEffect, useEffect, memo } from 'react'
import { LogoFull } from '@/shared/components/Logo'
import { IconButton, CommandBar, PrimaryButton, CommandBarButton, FocusTrapZone, Stack } from '@fluentui/react'
import NavMenu, { NavMenuMobile } from '@/shared/components/NavMenu'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import debounce from 'lodash.debounce'
import { Person } from '@/shared/types/person'
import useCommandBarProps from './hooks/useCommandBarProps'
import { useGetNavMenuData } from '@/shared/constants/navMenuItems'
import { msalInstance } from '@/bootstrap/msal'
import { useSelector } from 'react-redux'
import { selectPerson } from '@/features/auth/redux/authSelectors'
import items from './constants/navItems'
import './NavBar.scss'
import clearPersistStorage from '@/bootstrap/redux/clearPersistStorage'
import ImpersonationBanner from './components/ImpersonationBanner'
import { Link } from 'react-router-dom'
import { NavMenuProps } from '../NavMenu/NavMenu'

interface Props {
  style?: CSSProperties;
  navMenuProps?: NavMenuProps
}

function NavBar(props: Props) {
  const { style } = props
  const person: Person = useSelector(selectPerson)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const navMenuRef = useRef()
  const { t } = useTranslation('common')
  const itemIds = useGetNavMenuData()

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen((prevState) => !prevState)
  }

  const handleSignOut = async () => {
    await clearPersistStorage()
    msalInstance.logoutRedirect()
  }

  useEffect(() => {
    if (mobileMenuOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'visible'
  }, [mobileMenuOpen])

  const { desktopProps, mobileProps } = useCommandBarProps(items, person)
  const resetFocusOnResize = debounce(() => {
    if (window.innerWidth > 768 && mobileMenuOpen) {
      setMobileMenuOpen(false)
    }
  }, 200)

  useLayoutEffect(() => {
    window.addEventListener('resize', resetFocusOnResize)
    return () => {
      window.removeEventListener('resize', resetFocusOnResize)
    }
  }, [resetFocusOnResize])

  return (
    <nav 
      className="c-nav" 
      style={{ ...style, ...(mobileMenuOpen ? { position: 'fixed' } : {}) }}
      role="navigation"
    >
      <Link to='/' className="c-nav-logo">
        <LogoFull height="40px" fill="#FFFFFF" />
      </Link> 
      {/* Mobile */}
      {!mobileMenuOpen ? <ImpersonationBanner /> : null}
      <NavMenu className="c-navmenu" itemIds={itemIds} {...props.navMenuProps} />
      <FocusTrapZone disabled={!mobileMenuOpen}>
        <IconButton 
          iconProps={{ iconName: mobileMenuOpen ? 'Cancel' : 'GlobalNavButton' }} 
          className={cn('c-nav__mobile-menu-toggle', { '--open': mobileMenuOpen })}
          onClick={handleMobileMenuOpen}
          ariaLabel={mobileMenuOpen ? 'Close Site Menu' : 'Open Site Menu'}
          aria-expanded={mobileMenuOpen}
          aria-controls="navmenu"
        />
        <div 
          data-automationid="mobilenavcontainer"
          ref={navMenuRef}
          className={cn('c-nav__mobile-menu-content', { '--open': mobileMenuOpen })}
        >
          <Stack verticalFill disableShrink className="c-nav__mobile-stack">
            <NavMenuMobile ids={itemIds} />
            <div className='mobile-section-separator'>
              <div className='line'></div>
            </div>
            {mobileProps.map((item) => (
              <CommandBarButton { ...item } key={item.key} />
            ))}
            <PrimaryButton 
              text={t('nav:signout')} 
              onClick={handleSignOut}
              className="c-nav__fw-button"
            />
          </Stack>
        </div>
      </FocusTrapZone>

      {/* Desktop */}
      <CommandBar 
        items={desktopProps}
        className={`c-nav__command-bar items-${desktopProps?.length}`}
        styles={{ 
          root: { 
            backgroundColor: 'transparent', 
            height: '40px' 
          } 
        }}
      />

    </nav>
  )
}

export default memo(NavBar)
