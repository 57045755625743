import { useLayoutEffect, useRef } from 'react';
import { ComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react'

import './BoxFolderPermisionDropdown.scss'

export const INVESTMENT_MATERIALS_EMPLOYEE = 'INVESTMENT_MATERIALS_EMPLOYEE'
export const INVESTMENT_MATERIALS_CONTACT = 'INVESTMENT_MATERIALS_CONTACT'
export const TRANSACTION_DOCUMENTS = 'TRANSACTION_DOCUMENTS'
export const ONBOARDING_MATERIALS = 'ONBOARDING_MATERIALS'


export interface BoxFolderPermisionDropdownProps {
  disabled: boolean,
  selectedOption: 'INVESTMENT_MATERIALS_EMPLOYEE' | 'INVESTMENT_MATERIALS_CONTACT' | 'INVESTMENT_MATERIALS_ONBOARDING_GROUP' | 'TRANSACTION_DOCUMENTS' | 'ONBOARDING_MATERIALS',
  onValueChange: (selectedOption: 'INVESTMENT_MATERIALS_EMPLOYEE' | 'INVESTMENT_MATERIALS_CONTACT' | 'INVESTMENT_MATERIALS_ONBOARDING_GROUP' | 'TRANSACTION_DOCUMENTS' | 'ONBOARDING_MATERIALS') => void,
}

const BoxFolderPermisionDropdown = ({ disabled = false, selectedOption = 'INVESTMENT_MATERIALS_EMPLOYEE', onValueChange }: BoxFolderPermisionDropdownProps) => {
  const dropdownRef = useRef(null)

  useLayoutEffect(() => {
    if (dropdownRef) {
      const container = dropdownRef.current as HTMLElement
      
      if (container) {
        const baseContainer = container.querySelector('.ms-ComboBox') as HTMLElement
        
        if (baseContainer) {
          const input = container.querySelector('input') as HTMLElement
          
          if (input) {
            input.setAttribute('disabled', 'disabled')
          }
        }
      }
    }
  },[selectedOption])

  const boxFolderPermisionDropdownStyles: Partial<IComboBoxStyles> = { 
    root: {
      width: 380,
      outline: 'none',
      lineHeight: 'normal',
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 380
    },
  };
  const boxFolderPermisionDropdownOptions = [
    {
      key: 'INVESTMENT_MATERIALS_EMPLOYEE',
      text: 'Investment Materials - AD Group'
    },
    {
      key: 'INVESTMENT_MATERIALS_CONTACT',
      text: 'Investment Materials - Unassigned Client Contacts'
    },
    {
      key: 'INVESTMENT_MATERIALS_ONBOARDING_GROUP',
      text: 'Investment Materials - Client Onboarding AD Group'
    },
    {
      key: 'TRANSACTION_DOCUMENTS',
      text: 'Transaction Documents - AD Groups'
    },
    {
      key: 'ONBOARDING_MATERIALS',
      text: 'Onboarding Materials - AD Group'
    },
  ]

  const onBoxFolderPermisionDropdownChange = (option?: IComboBoxOption) => {
    const selectedOption = (option?.key || '') as string
    onValueChange(selectedOption as any)
  }

  return (
    <ComboBox
      ref={dropdownRef}
      disabled={disabled}
      className={`BoxFolderPermisionDropdown ${selectedOption ?  'has-value' : ''}`}
      selectedKey={selectedOption}
      options={boxFolderPermisionDropdownOptions}
      styles={boxFolderPermisionDropdownStyles}
      autoComplete='off'
      allowFreeform={false}
      useComboBoxAsMenuWidth={true}
      placeholder='Box Folder Permission'
      onChange={(event, option) => onBoxFolderPermisionDropdownChange(option)}
    />
  )
}

export default BoxFolderPermisionDropdown
