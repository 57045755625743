import { PerformanceRawData } from '@/shared/api/services/portfolioService'
import { ICellRendererParams } from 'ag-grid-community'
import { TooltipHost } from '@fluentui/react'
import styles from './PerformanceGridAutoGroupCellRenderer.module.scss'

export const EXPORT_CELL_CLASS = 'PerformanceGridAutoGroupCellRenderer'

export const PerformanceGridAutoGroupCellRenderer = (props: ICellRendererParams) => {
    const row = props.data as PerformanceRawData
    const { Name: name, DisplayPLIType, PLIType, StatusAggregate, footNoteDataFlags } = row || {}
    const displayType = PLIType || DisplayPLIType
    let title = ''
    let statusAggregateDisplayFlag = ''
    let statusAggregateTooltip = ''
    const footNoteDisplayFlag = footNoteDataFlags && footNoteDataFlags.length ? footNoteDataFlags.join(' ') : ''
    const footNoteTooltip = footNoteDisplayFlag && footNoteDisplayFlag.length ? 'Please view the Disclosures tab for End Notes.' : ''

    if (displayType === 'Sleeve') {
      title += displayType + '(L' + props.data.Lvl + ')'
    }
    else {
      title += displayType
    }

    if (StatusAggregate) {
      if (StatusAggregate === 'Preliminary') {
        statusAggregateDisplayFlag = '*'
        statusAggregateTooltip = 'This row contains preliminary data.'
      } else if (StatusAggregate === 'PROXY') {
        statusAggregateDisplayFlag = '†'
        statusAggregateTooltip = 'This row contains proxy data.'
      }
    }

    return (
      <div className={styles.container}>
        <div className={`${styles.text} ${styles[`${displayType}Text`]}`}>
          <span className='Value'>{name}&nbsp;</span>

          <TooltipHost content={statusAggregateTooltip}>
            <sup className={styles.StatusAggregate}>
              {statusAggregateDisplayFlag}
            </sup>
          </TooltipHost>

          <TooltipHost content={footNoteTooltip}>
            <sup className={styles.FootNote}>
              {footNoteDisplayFlag}
            </sup>
          </TooltipHost>
        </div>
      </div>
    )
}
