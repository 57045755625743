import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { selectHasToken, selectIsImpersonating, selectPermissions, selectSecurityRoles } from '../redux/authSelectors'
import { Permission } from '../types'
import getHomeRoutePath from '../helpers/getHomeRoutePath'
import useAccessRulesQuery from '../hooks/useAccessRulesQuery'
import { useGetDashboardSettings } from '@/shared/api/services/legacyService'
import { isFormattedDateValid } from '@/lib/common/services/date/DateService'

interface Props extends RouteProps {
  authority?: Permission
  path: string;
}

export const isAsOfDateValid = (asOfDate?: string) => {
  try {
    return !asOfDate || isFormattedDateValid(asOfDate)
  } catch (error) {
    console.error(`Invalid as of date ${asOfDate}.`, error)
    return false
  }
}

export default function ProtectedRoute(props: Props) {
  const { path, exact, component, authority, location, ...rest } = props
  const permissions: Array<Permission> = useSelector(selectPermissions)
  const hasToken = useSelector(selectHasToken)
  const securityRoles = useSelector(selectSecurityRoles)
  const { isSuccess, data, isLoading } = useAccessRulesQuery()
  const { isSuccess: settingsSuccess, data: settingsData, isLoading: settingsLoading } = useGetDashboardSettings()
  const isImpersonating = useSelector(selectIsImpersonating)
  const defaultPath = getHomeRoutePath(permissions, securityRoles) ?? '/'
  
  if (hasToken && authority && !permissions.includes(authority)) {
    console.error('User does not have access to this route.')
    return <Redirect to={defaultPath} />
  }

  if (path.startsWith('/document')) {
    if (isSuccess && !data?.documents) return <Redirect to={defaultPath} />
    if (isLoading) return null
  }

  if (path.startsWith('/messages') && isImpersonating) {
    if (isSuccess && !data?.documents) return <Redirect to={defaultPath} />
    if (isLoading) return null
  }

  if (path.startsWith('/dashboard/onboarding')) {
    if (settingsSuccess && !settingsData?.enableClientOnboardingDashboard) return <Redirect to={defaultPath} />
    if (settingsLoading) return null
  }

  // portfolio dashboard
  if (path.startsWith('/portfolio')) {
    // CA Employee not impersonating should not access portfolio route
    if (securityRoles?.isCAEmployee && !securityRoles?.isImpersonating) {
      return <Redirect to={defaultPath} />
    }

    const asOfDateMatch = (location?.pathname || '').match(/([0-9]{4}\-[0-9]{2}\-[0-9]{2})/)
    const asOfDate = asOfDateMatch && asOfDateMatch[1]

    // redirects to default path in case of invalid as of date (non month end or date greater than current time previous month end)
    if (asOfDate && !isAsOfDateValid(asOfDate)) {
      return <Redirect to={defaultPath} />
    }
  }

  // main investment dashboard
  if (path === '/') {
    const asOfDateMatch = (location?.search || '').match(/asOfDate\=([0-9]{4}\-[0-9]{2}\-[0-9]{2})/)
    const asOfDate = asOfDateMatch && asOfDateMatch[1]

    // redirects to default path in case of invalid as of date (non month end or date greater than current time previous month end)
    if (asOfDate && !isAsOfDateValid(asOfDate)) {
      return <Redirect to={defaultPath} />
    }
  }

  return (
    <Route
      path={path}
      exact={exact}
      component={component}
      {...rest}
    />
  )
}