import { GridApi } from 'ag-grid-community'
import { CurrencyColumn, GroupColumn, LinkColumn, PercentageColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { IPortfolioSummary, getPerformanceValue, getPortfolioName, PERFORMANCE_MEDIAN_KEYS, useGetStatsSummaryQuery } from '@/shared/api/services/portfolioService'
import { ChangeMktValueColumn } from './cellRenderer/ChangeMktValueColumn'
import SkeletonClientPortfolioList from '../SkeletonClientPortfolioList'
import { GeneralError } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'

import './PortfolioList.scss'

export interface PortfolioListProps {
  asOfDate: string,
  accountId: string,
  sendGridApi: (gridApi : GridApi) => void
}

const PortfolioList = ({
  asOfDate,
  accountId,
  sendGridApi
}: PortfolioListProps) => {
  const isMobile = useIsMobile('xs') 
  const { data, isFetching, isError, refetch } = useGetStatsSummaryQuery({ accountId, asOfDate })

  if (isError) {
    return (
      <GeneralError title='Failed to load Client Portfolios' onClick={refetch} />
    )
  }

  const getCurrencySymbol = (data: IPortfolioSummary) => {
    return data?.portfolioSummary?.currencySymbol
  }

  const click = (data: IPortfolioSummary) => { 
    return `/portfolio/${data.portfolioId}/${asOfDate}`
  }

  const isPortfolioDisabled = (data: IPortfolioSummary) => { 
    return !!data?.isError
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    sendGridApi(gridApi)
  }

  const roundToOneDecimal = (value: number): number => {
    return Math.round(value * 10) / 10
  }

  return (
    <>
      { isFetching ? <SkeletonClientPortfolioList /> : 
      <DataGrid
        showToolBar={false}
        rows={data}
        enablePagination={false}
        domLayout='normal'
        onInit={onGridInit}
      >
        <LinkColumn pinned='left' lockPinned={true} disabled={isPortfolioDisabled} onGenerateLink={click} title='PORTFOLIO' field='shortName' cellClass='portfolio-name' checkboxSelection={false} disableFilterColumn={true}
          valueGetter={(row: IPortfolioSummary) => getPortfolioName(row) } />
        <CurrencyColumn decimalScale={1} title='MARKET VALUE' width={130} field='portfolioSummary.marketValue' checkboxSelection={false} disableFilterColumn={true} getCurrencySymbol={getCurrencySymbol} />
        <ChangeMktValueColumn headerClass='text-break-right' title='CHANGE IN MARKET VALUE' field='portfolioSummary.changePercent' width={120} disableFilterColumn={true} />
        <GroupColumn title='Performance (%)'>
          <PercentageColumn customFilter={PERFORMANCE_MEDIAN_KEYS} title='MTD' field='portfolioSummary.mtdReturn' width={70} headerClass='c-performance-group-header' disableFilterColumn={true} valueGetter={value => {
            return getPerformanceValue(value, 'mtdReturn', 'dashboard')
          }}/>
          <PercentageColumn customFilter={PERFORMANCE_MEDIAN_KEYS} title='QTD' field='portfolioSummary.qtdReturn' width={70} headerClass='c-performance-group-header' disableFilterColumn={true} valueGetter={value => {
            return getPerformanceValue(value, 'qtdReturn', 'dashboard')
          }}/>
          <PercentageColumn customFilter={PERFORMANCE_MEDIAN_KEYS} title='YTD' field='portfolioSummary.ytdReturn' width={70} headerClass='c-performance-group-header' disableFilterColumn={true} valueGetter={value => {
            return getPerformanceValue(value, 'ytdReturn', 'dashboard')
          }}/>
          <PercentageColumn customFilter={PERFORMANCE_MEDIAN_KEYS} title='SINCE INCEPTION' field='portfolioSummary.itdReturn' width={100} headerClass='c-performance-group-header text-break-right' disableFilterColumn={true} valueGetter={value => {
            return getPerformanceValue(value, 'itdReturn', 'dashboard')
          }}/>
        </GroupColumn>
      </DataGrid>}
    </>
  )
}

export default PortfolioList