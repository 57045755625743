
import { useState } from 'react'
import { useGetDashboardSettings } from '@/shared/api/services/legacyService'
import Allocation from './Allocation/Allocation'
import MarketValue from './MarketValue/MarketValue'
import PerformanceSummary from './PerformanceSummary/PerformanceSummary'
import RecentTrade from './RecentTrades/RecentTrade'
import TopFiveHolding from './TopFiveHolding/TopFiveHolding'

import './ClientPortfolioHome.scss'

export interface ClientPortfolioHomeProps {
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string
}

export default function ClientPortfolioHome({ 
  portfolioId, 
  asOfDate,
  sleeveId,
}: ClientPortfolioHomeProps) {
  const { data } = useGetDashboardSettings()
  const [showTotalPortfolioButton, setShowTotalPortfolioButton] = useState(false)
  const [totalPortfolioId, setTotalPortfolioId] = useState('')
  return (
    <div>
      <div className='d-flex allocation-line'>
        { data?.enablePortfolioDashboardMarketValue ? (
          <MarketValue portfolioId={portfolioId} asOfDate={asOfDate} showTotalPortfolioButton={false} totalPortfolioId={totalPortfolioId} />
        ) : null } 
        { data?.enablePortfolioDashboardAllocation ? (
          <Allocation portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId} setShowTotalPortfolioButton={setShowTotalPortfolioButton} setTotalPortfolioId={setTotalPortfolioId}/> 
        ) : null } 
      </div>
      { data?.enablePortfolioDashboardPerformance ? (
        <PerformanceSummary portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId} showTotalPortfolioButton={false}/>
      ) : null } 
      <div className={`d-flex bottom-row top-5-holding-line ${!data?.enableClientDashboardRecentTrades ? 'no-recent-trades' : ''}`}>
        { data?.enableClientDashboardRecentTrades ? (
          <RecentTrade portfolioId={portfolioId} asOfDate={asOfDate} />
        ) : null } 
        { data?.enablePortfolioDashboardTopHoldings ? (
          <TopFiveHolding portfolioId={portfolioId} asOfDate={asOfDate} />
        ) : null } 
      </div>
    </div>
  )
}
