import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IPortfolioSummary, getPortfolioName, useGetStatsSummaryQuery } from '@/shared/api/services/portfolioService'
import SkeletonClientPortfolioList from '../SkeletonClientPortfolioList'

import './PortfolioListMobile.scss'

export interface PortfolioListMobileProps {
  asOfDate: string,
  accountId: string,
}

export default function PortfolioListMobile({
  asOfDate,
  accountId,
}: PortfolioListMobileProps) {
  const { data, isFetching, isError, refetch } = useGetStatsSummaryQuery({ accountId, asOfDate })

  const click = (data: IPortfolioSummary) => {
    if (data) {
      return `/portfolio/${data.portfolioId}/${asOfDate}`
    }
    return '/'
  }
  
  const roundToOneDecimal = (value: number): string | number => {
    const str = value.toString();
    const decimalIndex = str.indexOf('.');
    
    if (decimalIndex === -1) {
        // If there is no decimal point, just return the number as is
        return str;
    }
    
    // Extract the part of the string up to the desired decimal places
    const result = str.substring(0, decimalIndex + 2);
    return result;
  }  

  const currentMarketValue = (value) => {
    if(value === null || value === undefined) {
      return <span className='nullValue'> --- </span>
    }

    return (
      <div className='ChangeMktValueColumn'>
        <div className={`ChangeMktValue ${value > 0 ? 'Positive' : 'Negative'}`}>
          <FontAwesomeIcon icon={["fas", `${value > 0 ? 'arrow-up' : 'arrow-down'}`]} /> {value > 0 ? roundToOneDecimal(value) : roundToOneDecimal(value).toString().replace('-', '')}%
        </div>
      </div>
    )
  }

  return isFetching ? <SkeletonClientPortfolioList /> : 
    (!data || !data.length) ? (
      <div className='empty-data'>
        <span><strong>No Rows To Show</strong></span>
      </div>
    ) : (
      <div className='portfolio-list-mobile'>
        {data?.map((data, key) => {
          return (
            <div className='row' key={key}>
              <Link to={(e) => click(data)} className='portfolio-list-mobile-row'>
                <div className='portfolio-list-mobile-text'>{getPortfolioName(data)}<FontAwesomeIcon className='icon-left' icon={["fas", 'chevron-right']} /></div>
                <div className='portfolio-list-mobile-data'>
                  <div className='portfolio-list-mobile-asOfDate'>
                    <span className={`${(data && data.portfolioSummary && data.portfolioSummary.marketValue) < 0 ? 'negative-number' : ''}`}>
                      <NumberFormat
                        value={(data && data.portfolioSummary && data.portfolioSummary.marketValue)}
                        displayType="text"
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={1}
                        fixedDecimalScale={true}
                        renderText={(value) => {
                          const isNegative = `${value}`.trim().startsWith('-')
                          const currencySymbol = value ? (data && data.portfolioSummary && data.portfolioSummary.currencySymbol) : ''
                          const formattedValue = isNegative ? currencySymbol + "(" + (value + "").replace("-", "") + ")" : currencySymbol + value
                          return value ? formattedValue : '---'
                        }}
                      />
                    </span>
                  </div>
                  <div className='currentMVWrapper'>
                    {currentMarketValue((data && data.portfolioSummary && data.portfolioSummary.changePercent))}
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div> 
  )
}
