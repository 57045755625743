import useAccessRulesQuery from '@/features/auth/hooks/useAccessRulesQuery'
import { selectPermissions } from '@/features/auth/redux/authSelectors'
import { Permission } from '@/features/auth/types'
import { NavMenuItems } from '@/shared/constants/navMenuItems'
import { INavbarNavigationDisplayResult } from '@/shared/types/swagger'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { filterEmptyNest } from '../helpers/emptyNest'
import { NavMenuItem } from '../types'

const NavItemsDisplayMap: Partial<Record<NavMenuItems, keyof INavbarNavigationDisplayResult>> = {
  [NavMenuItems.OpticaResearchPrivate]: 'opticaResearchPrivate',
  [NavMenuItems.OpticaBenchmarks]: 'opticaBenchmark',
  [NavMenuItems.OpticaResearchPublic]: 'opticaResearchPublic',
  [NavMenuItems.OpticaPeers]: 'opticaPeers',
  [NavMenuItems.ILevel]: 'iLevel',
  [NavMenuItems.PortfolioWorkbench]: 'portfolioWorkbench',
  [NavMenuItems.Documents]: 'documents'
}

export default function useNavItemsFiltering(items: Array<NavMenuItem>): Array<NavMenuItem> {
  const { data, isLoading, isError, hasToken } = useAccessRulesQuery()
  const permissions: Array<Permission> = useSelector(selectPermissions)
  const _items = items

  const shouldDisplayById = useCallback((id: NavMenuItems)  => {
    if (!hasToken) return false
    if (!data) return false
    if (id in NavItemsDisplayMap) {
      const displayKey = NavItemsDisplayMap[id]
      return data[displayKey]
    }
    return true
  }, [hasToken, data])

  const filterByNavbarRule = useCallback((item: NavMenuItem) => {
    const shouldDisplay = shouldDisplayById(item.id)
    if (isLoading) {
      if (item.id in NavItemsDisplayMap) return false
      return true
    }
    return shouldDisplay
  }, [isLoading, shouldDisplayById])

  const disableWhenLoading = useCallback((item: NavMenuItem) => ({
    ...item,
    disabled: isLoading,
  }), [isLoading])
  
  if (isError) return []

  return [..._items]
    .filter((item) => item.authority ? permissions.includes(item.authority) : true)
    .filter(filterByNavbarRule)
    .map(disableWhenLoading)
    .map((item) => {
      if (item.itemIds) {
        return {
          ...item,
          itemIds: item.itemIds.filter(shouldDisplayById)
        }
      }
      return item
    })
    .filter(filterEmptyNest)
}