import { DEFAULT_COLUMNDEFS, IDataGridCellStyleParams, IDataGridColumnProps, PLACEHOLDER } from '@/lib/common/components/grid/Columns'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { TooltipHost } from '@fluentui/react'

import './PerformanceSleeveColumn.scss'

export const PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS = 'PerformanceSleeveCell'

export const PerformanceSleeveCellRenderer = (props: PerformanceSleeveCellRendererProps) => {
    const { data, value, cellClass } = props
    const { StatusAggregate, footNoteDataFlags } = data
    const formattedValue = value || ''
    let statusAggregateDisplayFlag = ''
    let statusAggregateTooltip = ''
    const rowToolTip = props.customTooltipValueGetter ? (props.customTooltipValueGetter(data) || '') : ''
    const footNoteDisplayFlag = footNoteDataFlags && footNoteDataFlags.length ? footNoteDataFlags.join(' ') : ''
    const footNoteTooltip = footNoteDisplayFlag && footNoteDisplayFlag.length ? 'Please view the Disclosures tab for End Notes.' : ''

    if (StatusAggregate) {
      if (StatusAggregate === 'Preliminary') {
        statusAggregateDisplayFlag = '*'
        statusAggregateTooltip = 'This row contains preliminary data.'
      } else if (StatusAggregate === 'PROXY') {
        statusAggregateDisplayFlag = '†'
        statusAggregateTooltip = 'This row contains proxy data.'
      }
    }

    return (
      <div className={`PerformanceSleeveCellRenderer ${formattedValue.replace(' ', '')} ${cellClass && cellClass(props)}`}>
        <TooltipHost content={rowToolTip}>
          <span className='Value'>{value}&nbsp;</span>
        </TooltipHost>

        <TooltipHost content={statusAggregateTooltip}>
          <sup className='StatusAggregate'>
            {statusAggregateDisplayFlag}
          </sup>
        </TooltipHost>

        <TooltipHost content={footNoteTooltip}>
          <sup className='FootNote'>
            {footNoteDisplayFlag}
          </sup>
        </TooltipHost>
      </div>
    )
}

export interface PerformanceSleeveCellRendererProps extends ICellRendererParams {
  customTooltipValueGetter?: (data) => string,
  cellClass?: (params: IDataGridCellStyleParams) => string,
}

export interface PerformanceSleeveColumnProps extends IDataGridColumnProps {
  customTooltipValueGetter?: (data) => string,
  cellClass?: (params: IDataGridCellStyleParams) => string,
}
export function PerformanceSleeveColumn(props: PerformanceSleeveColumnProps) {
  return PLACEHOLDER
}
PerformanceSleeveColumn.columnDefs = <TRow extends any>(
  props: PerformanceSleeveColumnProps,
  row: ICellRendererParams
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: !props.disableFilterColumn ? 'agTextColumnFilter' : false,
  floatingFilter: !props.disableFilterColumn,
  cellRendererFramework: PerformanceSleeveCellRenderer,
  cellRendererParams: { ...props },
  cellClassRules: props.cellClassRules,
  cellClass: PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS,
  headerClass: props.headerClass,
  onCellClicked: props.onCellClicked,
  resizable: props.resizable,
  headerName: props.headerName,
})
