import { ELinks } from '@/shared/types/Links'
import { ILegacyAppDisplayRuleResult } from '@/shared/types/swagger'

export const moreLinksDisplayMap: Partial<Record<ELinks, keyof ILegacyAppDisplayRuleResult>> = {
  [ELinks.MPME]: 'cAmPME',
  [ELinks.PreliminaryPerformanceBulletin]: 'preliminaryPerformanceBulletin',
  [ELinks.AnnualManagerReport]: 'annualManagerReport',
  [ELinks.ManagerNotices]: 'managerNotices',
  [ELinks.MMOS]: 'mmos',
  [ELinks.PIMOS]: 'pios',
  [ELinks.ProprietaryPIB]: 'proprietaryPrivateInvestmentBenchmarks',
  [ELinks.HistoricalPIB]: 'historicalPrivateInvestmentBenchmarks',
  [ELinks.ClientSnapshot]: 'clientSnapshot',
  [ELinks.AnnualInvestmentPRSurvey]: 'clientInstitutionTypes',
  [ELinks.InvestmentPRQuarterlySurvey]: 'investmentPoolReturnsQuarterlySurvey',
  [ELinks.PRSnapshot]: 'poolReturnsSnapshot',
  [ELinks.ExhibitFinder]: 'exhibitFinder',
  [ELinks.IndexSnapshots]: 'indexSnapshots',
  [ELinks.ProprietaryPIB]: 'proprietaryPrivateInvestmentBenchmarks'
}

export const displayKeyLinkMap: Partial<Record<keyof ILegacyAppDisplayRuleResult, ELinks>> = 
  Object.entries(moreLinksDisplayMap).reduce((acc, [key, value]) => {
    acc[value] = key
    return acc
  }, {})

export default moreLinksDisplayMap