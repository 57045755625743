import { msalInstance } from '@/bootstrap/msal'
import clearPersistStorage from '@/bootstrap/redux/clearPersistStorage'
import { ICommandBarItemProps, DirectionalHint } from '@fluentui/react'
import MessagesList from '../components/NotificationList'

// items cannot contain NotificationList directly as it contains a hook and causes an error. This wraps around NotificationList to safely call the hook.
const MessagesListWrapper = () => {
  return (
    <MessagesList />
  )
}

const items = {
  messages: {
    key: 'messages',
    subMenuProps: {
      isBeakVisible: true,
      beakWidth: 16,
      directionalHint: DirectionalHint.bottomRightEdge,
      items: [
        { 
          key: 'messagesList',
          onRender: MessagesListWrapper,
          data: { testing: 'test' }
        }
      ]
    }
  } as ICommandBarItemProps,
  support: {
    iconProps: { iconName: 'info' },
    key: 'support',
    href: '/support'
  } as ICommandBarItemProps,
  useractions: {
    key: 'useractions',
    subMenuProps: {
      items: [
        {
          iconProps: { iconName: 'Contact' },
          href: '/profile',
          key: 'myaccount',
        },
        {
          iconProps: { iconName: 'SignOut' },
          key: 'signout',
          onClick: async () => { 
            await clearPersistStorage()
            msalInstance.logoutRedirect() 
          }
        }
      ],
    }
  } as ICommandBarItemProps,
  myaccount: {
    key: 'myaccount',
    href: '/profile'
  } as ICommandBarItemProps
}

type NavItemsKeys = keyof typeof items
export type NavItems = Record<NavItemsKeys, ICommandBarItemProps>

export default items