import { IUserProfileModel } from '@/shared/types/swagger'
import { mainService } from '.'

const profileWithTags = mainService.enhanceEndpoints({ addTagTypes: ['Profile'],  })

const profileServices = profileWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getProfile: build.query<IUserProfileModel, void>({
        query: () => ({ url: '/CRM/currentprofile', method: 'GET' }),
        providesTags: ['Profile'],
        keepUnusedDataFor: 6000
      }),
    }
  },
})

export const { useGetProfileQuery  } = profileServices
