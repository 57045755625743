import { DEFAULT_DATE_FORMAT, formatDate, getMaxAsOfDate, isDateValid } from '@/lib/common/services/date/DateService'
import { Calendar, DatePicker, DateRangeType, getMonthEnd } from '@fluentui/react'
import { useEffect, useState } from 'react'

import './MonthEndSelector.scss'

export interface MonthEndSelectorProps {
  value?: Date
  minDate?: Date
  maxDate?: Date
  onValueChange?: (date: Date) => void
}

const MonthEndSelector = ({
  value = getMaxAsOfDate(),
  maxDate = getMaxAsOfDate(),
  minDate,
  onValueChange,
}: MonthEndSelectorProps) => {
  const currentDate = new Date()
  const [ mutationObserver, setMutationObserver ] = useState<MutationObserver | null>(null)
  
  useEffect(() => {
    return () => {
      // clears mutation observer on component unbound
      clearMutationObserver() 
    }
  }, [mutationObserver])

  const clearMutationObserver = () => {
    if (mutationObserver) {
      // unbinds observer event
      mutationObserver.disconnect()

      // clears state
      setMutationObserver(null)
    }
  }

  const disableElement = (element: HTMLElement) => {
    element.setAttribute('disabled', 'disabled')
    element.style.setProperty('color','#c8c6c4', 'important')
    element.style.setProperty('pointer-events', 'none', 'important')
    element.style.setProperty('background-color', '#ffffff', 'important')
  }

  const refreshTodayLink = (calloutElement: HTMLElement) => { 
    setTimeout(() => {
      const goToTodayElement = calloutElement.querySelector('.js-goToday') as HTMLElement

      if (goToTodayElement) {
        if (!isDateValid(currentDate)) {
          disableElement(goToTodayElement)
        }
      }
    })
  }


  const initCalloutObserver = (calloutElement: HTMLElement) => {
    // clears any cached mutation observer
    clearMutationObserver()

    // creates an observer instance linked to the callback function
    const observer = new MutationObserver((mutationList, currentObserver) => {
      refreshTodayLink(calloutElement)
    })
    
    // starts observing on callout element
    observer.observe(calloutElement, {
      childList: true,
      subtree: true,
    })

    setMutationObserver(observer)
    refreshTodayLink(calloutElement)
  }


  return (
    <DatePicker
      className='MonthEndSelector'
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      showGoToToday={false}
      calendarAs={(props) =>
        <Calendar
          {...props}
          highlightSelectedMonth
          dateRangeType={DateRangeType.Month}
          isDayPickerVisible={false}
        />
      }
      onSelectDate={(date) => {
        // emits value change
        if (onValueChange) {
          onValueChange(getMonthEnd(date))
        }

        // clears mutation observer on date selection
        clearMutationObserver()
      }}
      calloutProps={{
        onLayerMounted: () => {
          const calloutElement = document.querySelector('.ms-Callout.ms-DatePicker-callout') as HTMLElement
          if (calloutElement) {
            initCalloutObserver(calloutElement)
          }
        },
        onDismiss(event) {
          clearMutationObserver()
        },
      }}
      formatDate={(date) => formatDate(date, DEFAULT_DATE_FORMAT)}
    />
  )
}

export default MonthEndSelector