export enum EAccountType {
  'SSO_ClientWebsite SysAdmin' = 1,
  'SSO_ClientWebsite Content Admins',
  'SSO_ClientWebsite IT Support',
  'SSO_ClientWebsite Client Support',
  'SSO_ClientWebsite Client Onboarding',
  CARetiree,
  ClientContact,
  Investment,
}

export enum EUserCan {
  Access_Dashboard = 1,
  Access_Insights,
  Access_Events,
  Access_Additional_Info,
  View_Media,
  View_Optica_Links,
  View_iLevel_Links,
  Access_Client,
  Access_Clients,
  Send_Invites,
  Send_Messages,
  Impersonate_Users,
  Change_Onboarding_Status,
  Edit_Content,
  Manage_Events,
  Edit_Maintenance_Data,
  Access_SurveyLinks,
  Access_OnboardingDashboard,
  Edit_OnboardingDashboard,
  Access_More_Links,
  View_Portfolio_Workbench_Link,
  Edit_Account_Settings,
  View_Contact_TermsOfService_History,
  Reset_Contact_TermsOfService,
}

export type Permission = EUserCan