import { CommandButton, IButtonProps, Spinner } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { NavMenuItems, getNavMenuItemURL, navMenuItems } from '@/shared/constants/navMenuItems'
import { NavMenuItem } from './types'
import { useHistory, useLocation } from 'react-router'
import cn from 'classnames'
import useNavItemsFiltering from './hooks/useNavItemsFiltering'
import NavMenuAdminToolbar from './components/NavMenuAdminToolbar'
import { useSelector } from 'react-redux'
import { selectIsCAEmployee } from '@/features/auth/redux/authSelectors'

const keyIconMap = {
  researchPrivate: 'DocumentSearch',
  researchPublic: 'DocumentSearch',
  benchmarks: 'BranchCompare',
  peers: 'Group',
  mmos: 'Storyboard',
  pios: 'Storyboard',
  other: 'Storyboard'
}

export interface NavMenuProps {
  items?: Array<NavMenuItem>;
  itemIds?: Array<NavMenuItems>;
  className?: string;
}

export default function NavMenu({ items, itemIds, className }: NavMenuProps) {
  const history = useHistory()
  const { t } = useTranslation('common')
  const { pathname } = useLocation()
  const isCAEmployee = useSelector(selectIsCAEmployee)
  
  const _items = (items || itemIds).map((item) => typeof item === 'number' ? navMenuItems[item] : item as NavMenuItem)
  const filteredItems = useNavItemsFiltering(_items)

  if (!items && !itemIds) return null

  const getItemProps = (item: NavMenuItem) => {
    const text = t(`nav:${item.titleKey}`)
    const itemURL = getNavMenuItemURL(item, isCAEmployee)
    const commandBarProps: IButtonProps = {
      href: itemURL,
      target: item.target,
      disabled: item.disabled,
      text: text,
      className: pathname === itemURL ? 'is-expanded' : ''
    }
    const hasChildren = Boolean(item.itemIds)
    if (hasChildren) {
      commandBarProps.menuProps = { 
        items: item.itemIds.map((subitemId) => ({
          key: navMenuItems[subitemId].id.toString(),
          text: t(`nav:${navMenuItems[subitemId].titleKey}`),
          disabled: navMenuItems[subitemId].disabled,
          iconProps: { iconName: keyIconMap[navMenuItems[subitemId].titleKey] },
          href: getNavMenuItemURL(navMenuItems[subitemId], isCAEmployee),
          target: navMenuItems[subitemId].target,
        })) 
      }
    }
    return commandBarProps
  }

  return (
    <div className={cn('c-navmenu', className)}>
      {filteredItems
        .map((item) => {
          const commandBarProps = getItemProps(item)
          const itemURL = getNavMenuItemURL(item, isCAEmployee)
          return (
            <CommandButton 
              key={item.id} 
              {...commandBarProps} 
              onClick={itemURL?.startsWith?.('/') ? (e) => {e.preventDefault(); history.push(itemURL)} : undefined} 
            />
          )
        })
      }
      {/* // hide admin menu bar for prospect ? or rules ? */}
      <NavMenuAdminToolbar />
      {filteredItems.every(item => item.disabled) ? (
        <Spinner />
      ) : null}
    </div>
  )
}
