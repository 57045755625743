import { useState } from 'react'
import { PostModal, GeneralError } from '@/shared/components'
import { EUserCan } from '@/features/auth/types'
import { IYourTeam, useGetYourTeamQuery, useSaveTeamMembersMutation } from '@/shared/api/services/teamService'
import TeamPicker, { TeamPickerMember } from './components/TeamPicker'
import { IStackItemStyles, IStackTokens, Label, MessageBarType, PrimaryButton, Stack } from '@fluentui/react'
import useIsMobile from '@/shared/hooks/useIsMobile'
import modalSlice from '@/shared/redux/modalSlice'
import { useDispatch } from 'react-redux'
import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import withPermissions, { WithPermissionsProps } from '@/features/auth/components/withPermissions'
import yourTeamModalColumns from './components/YourTeamModalColumns'


export interface YourTeamProps extends WithPermissionsProps {
  accountId: string;
  buttonLabel?: string;
  modalLabel?: string;
}

const MODAL_PARAM_NAME = 'yourTeamModal'
const UI_MESSAGE_DISMISS_TIMEOUT = 3000
const stackToken: IStackTokens = { childrenGap: 16 }

const YourTeam = ({ accountId, buttonLabel, modalLabel }: YourTeamProps) => {
  const dispatch = useDispatch<appDispatch>()
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([] as TeamPickerMember[])
  const [addedTeamMembers, setAddedTeamMembers] = useState([] as IYourTeam[])
  const [deletedTeamMembers, setDeletedTeamMembers] = useState([] as IYourTeam[])
  const { data, isFetching, isLoading, refetch, isError, isUninitialized } = useGetYourTeamQuery(accountId)
  const [saveTeamMembers] = useSaveTeamMembersMutation()
  const inProgress = isUninitialized || isFetching || isLoading
  const isMobile = useIsMobile('md')
  const teamMembersList = [
    ...(data || []).filter(teamMember => {
      return !deletedTeamMembers.some(deletedTeamMember => `${deletedTeamMember.peopleId || ''}`.trim() === `${teamMember.peopleId || ''}`.trim())
    }),
    ...addedTeamMembers,
  ]

  const searchItemStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '80%'
    },
  }

  const buttonItemStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '20%'
    },
  }

  const handleTeamMembersSelected = (teamMembers: TeamPickerMember[]) => {
    setSelectedTeamMembers(teamMembers)
  }

  const handleAddMember = () => {
    setAddedTeamMembers([
      ...addedTeamMembers,
      ...selectedTeamMembers.map(m => m.sourceMember),
    ])
    setSelectedTeamMembers([])
  }

  const handleCloseClick = () => {
    refetch()
    setSelectedTeamMembers([])
    setAddedTeamMembers([])
    setDeletedTeamMembers([])
  }

  const handleEdit = (event: IYourTeam) => {
    // to do
  }

  const handleDelete = (event: IYourTeam) => {
    setDeletedTeamMembers([
      ...deletedTeamMembers,
      event
    ])
  }

  const handleConfirmClick = () => {
    saveTeamMembers({
      accountId,
      addedMembers: addedTeamMembers.map(a => ({
        accountId,
        peopleId: a.peopleId,
      })),
      deletedMembers: deletedTeamMembers.map(a => ({
        accountId,
        peopleId: a.peopleId,
      }))
    }).unwrap().then(o => {
      setSelectedTeamMembers([])
      setAddedTeamMembers([])
      setDeletedTeamMembers([])
      dispatch(createUIMessage({ 
        key: 'saveTeamMembersSuccess', 
        content: 'Saved Team Members successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
      dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
    }).catch(error => {
      dispatch(createUIMessage({ 
        key: 'saveTeamMembersFailure', 
        content: 'Failed to save Team Members.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
    })
  }

  const renderDescription = () => {
    return (
      <>
        <Stack className='team-picker' style={{ alignItems: 'flex-end' }} tokens={stackToken} horizontal={!isMobile}>
          <Stack.Item styles={searchItemStyles}>
            <Label>Search Team Member</Label>
            <TeamPicker maxTeamMembers={1} selectedTeamMembers={selectedTeamMembers} onTeamMembersSelected={handleTeamMembersSelected} />
          </Stack.Item>
          <Stack.Item styles={buttonItemStyles}>
            <PrimaryButton onClick={handleAddMember} disabled={!selectedTeamMembers || !selectedTeamMembers.length}>Add Member</PrimaryButton>
          </Stack.Item>
        </Stack>
      </>
    )
  }

  if (isError) {
    return (<GeneralError onClick={refetch} title='The manage teams modal could not be loaded.' />)
  }

  const columns = yourTeamModalColumns(handleEdit, handleDelete)
  const isSaveButtonDisabled = (!addedTeamMembers || !addedTeamMembers.length) && (!deletedTeamMembers || !deletedTeamMembers.length)

  return (
    <>
      <PostModal 
        className='c-team-modal'
        onClose={handleCloseClick}
        onConfirmClick={handleConfirmClick} 
        primaryLabel="Save"
        openButtonLabel={buttonLabel || "Manage Your Team"}
        modalTitle={modalLabel || "Manage Your Team"}
        openButtonId="manage-your-team-button"
        shimmerIsEnabled={inProgress}
        paramName={MODAL_PARAM_NAME}
        primaryIsDisabled={isSaveButtonDisabled}
        ariaLabels={{
          openButton: 'Open',
          closeButton: 'Close' 
        }}
        footer={<div style={{ marginTop: '24px' }}></div>}
        description={renderDescription()}
        tableProps={{
          columns,
          data: teamMembersList,
        }}
      />
    </>
  )
}

export default withPermissions([EUserCan.Edit_OnboardingDashboard, EUserCan.Edit_Account_Settings])(YourTeam)