import { useGetDashboardSettingsByAccountIdQuery } from '@/shared/api/services/legacyService'
import { Link } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Link as RRLink } from 'react-router-dom'

export interface ActionsCellProps {
  accountId: string;
}

export default function ManageDashboard({ accountId }: ActionsCellProps) {
  const { t } = useTranslation()
  const { data } = useGetDashboardSettingsByAccountIdQuery(accountId)
  return (
    <div>
      {
        data?.enableClientOnboardingDashboard ? (
          <Link as={RRLink} to={`/onboarding/${accountId}`}>{t('clients:manageDashboard')}</Link>
        ) : null
      }
    </div>
  )
}
