import { CellMouseOutEvent, CellMouseOverEvent, GridApi, RowClickedEvent } from 'ag-grid-community'
import { CurrencyColumn, IDataGridCellStyleParams, PercentageColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { IAllocation, IPortfolioSettings, PerformanceRawData, getGridExcelStyles, roundMarketValueWidgetValue, roundMarketValueWidgetValueInMillions } from '@/shared/api/services/portfolioService'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { PerformanceSleeveColumn } from '../../../Components/Grid/Column/PerformanceSleeveColumn'
import PerformanceGridFootNote from '../../../Components/Grid/FootNote/PerformanceGridFootNote'

import './AllocationGrid.scss'

export interface AllocationGridProps {
  rows: IAllocation[],
  portfolioSettings: IPortfolioSettings,
  sleeveId?: string,
  sleevesFilterMap?: {
    [key: string]: PerformanceRawData,
  }
  sendGridApi: (gridApi: GridApi) => void,
  onMouseLeave?: (event: CellMouseOutEvent) => void
  onRowClick?: (event: RowClickedEvent) => void
  onRowHover?: (event: any) => void
  onAllocationIndexUpdate?: (index: number) => void
}

const AllocationGrid = ({
  rows,
  portfolioSettings,
  sleeveId,
  sleevesFilterMap,
  sendGridApi,
  onAllocationIndexUpdate,
  onMouseLeave,
  onRowClick,
  onRowHover,
}: AllocationGridProps) => {
  const isLargeMobile = useIsMobile('mm')
  const isMediumMobile = useIsMobile('md')
  const isxLargeScreen = window.outerWidth > 1440
  const allocationSummarySettings = portfolioSettings?.allocationSummary
  const getInvestmentCellStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    styles['boxShadow'] = `5px 0px ${data.color} inset`
    styles['boxSizing'] = 'border-box !important'
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    return styles
  }
  const getCurrencySymbol = (data) => {
    return data?.currencySymbol
  }

  const getRowStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }

    if (data?.name === 'Total Assets') {
      styles['font-weight'] = '600'
    }

    return styles
  }

  const getCellClass = (params: IDataGridCellStyleParams) => {
    if (params.rowIndex !== 0) {
      return 'c-allocation-tab'
    }
    return ''
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    sendGridApi(gridApi)
  }

  const textColumnWidth = isMediumMobile ? 240 : (isLargeMobile ? 210 : 230)

  const isFootNoteHidden = !(rows || []).some(r => !!r.StatusAggregate)

  const onHover = (event: CellMouseOverEvent) => {
    const sleeveIdIndex = rows.findIndex(element => event.data.sleeveId === element['sleeveId'])
    const isSleeveIdIndexValid = sleeveIdIndex !== -1

    if (isSleeveIdIndexValid) {
      onAllocationIndexUpdate(sleeveIdIndex - 1)
    }

    if (onRowHover) {
      onRowHover(event)
    }
  }

  const getToolTipText = (data) => {
    // skip tooltip in case of no sleeve id in the route (Total Assets) and current node has no Level (0 / Total Assets)
    if (!sleeveId && !data.Lvl) {
      return ''
    }

    // get sleeve level from current node
    const currentSleeveId = `${data && data.id}`

    // do not show click to select on current selected sleeve or disabled sleeve
    if ((sleeveId !== currentSleeveId) && (!sleevesFilterMap[currentSleeveId] || !sleevesFilterMap[currentSleeveId].disabled)) {
      return `Click to see ${data.name}'s Data`
    }

    return ''
  }

  return (
    <div className='c-portfolio-allocation'>
      <DataGrid
        className='AllocationGrid'
        showToolBar={false}
        rows={rows}
        enablePagination={false}
        domLayout='autoHeight'
        onInit={onGridInit}
        excelStyles={getGridExcelStyles({
          currencySymbol: portfolioSettings?.currency?.symbol,
          dateFormat: portfolioSettings?.dateFormat,
        })}
        onRowClick={onRowClick}
        onRowMouseOut={onMouseLeave}
        onRowHover={onHover}
      >
        <PerformanceSleeveColumn pinned='left' lockPinned={true} disableFilterColumn={true} title=' ' width={textColumnWidth} resizable={true} headerName='nameColumn' field='name' checkboxSelection={false} cellClass={getCellClass} cellStyle={getInvestmentCellStyle} customTooltipValueGetter={getToolTipText} />
        <CurrencyColumn hide={!allocationSummarySettings?.marketValueInMillions?.display} headerClass='six-text-break-right' title='Current Market value (M)' disableFilterColumn={true} field='currentMktValue' cellStyle={getRowStyle} getCurrencySymbol={getCurrencySymbol} width={isxLargeScreen? 150 : isLargeMobile ? 145 : 135}
          decimalScale={1} valueGetter={value => {
            return roundMarketValueWidgetValueInMillions(value?.currentMktValue)
          }} />
        <CurrencyColumn hide={!allocationSummarySettings?.marketValue?.display} headerClass='text-break-right' title='Current Market value' disableFilterColumn={true} field='currentMktValue' cellStyle={getRowStyle} getCurrencySymbol={getCurrencySymbol} width={isxLargeScreen? 150 :isLargeMobile ? 135 : 125}
          decimalScale={0} valueGetter={value => {
            return roundMarketValueWidgetValue(value?.currentMktValue)
          }} />
        <PercentageColumn hide={!allocationSummarySettings?.allocation?.display} headerClass='four-text-break-right' title='Actual Allocation (%)' disableFilterColumn={true} field='actualAllocation' cellStyle={getRowStyle} width={isxLargeScreen? 160 : isLargeMobile ? 120 : 140} />
        <PercentageColumn hide={!allocationSummarySettings?.target?.display} headerClass='text-break-right' title='Long term target (%)' disableFilterColumn={true} field='longTrmTrgt' cellStyle={getRowStyle} width={isxLargeScreen ? 150 : 140} />
        <PercentageColumn hide={!allocationSummarySettings?.longTermTargetVariance?.display} headerClass='six-text-break-right' title='Long term target variance (%)' disableFilterColumn={true} field='longTrmTrgtVariance' cellStyle={getRowStyle} width={isxLargeScreen ? 150 : 140} />
        <PercentageColumn hide={!allocationSummarySettings?.targetRange?.display} headerClass='text-break-right' title='Target Range (%)' disableFilterColumn={true} field='TgtRng' cellStyle={getRowStyle} width={isxLargeScreen ? 150 : 140} />
        <PercentageColumn hide={!allocationSummarySettings?.interimTargetVariance?.display} headerClass='six-text-break-right' title='Interim Targe Variance (%)' disableFilterColumn={true} field='longTrmTrgtVariance' cellStyle={getRowStyle} width={isxLargeScreen ? 150 : 140} />
      </DataGrid>
      <PerformanceGridFootNote additionalClassName='AllocationFootNote' hidden={isFootNoteHidden} showTitle={false} />
    </div>
  )
}

export default AllocationGrid
